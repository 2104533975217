import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from '../components/Loading';
// Vistas Front end
const Login = lazy(() => import('../views/Login'));
const ResetPassword = lazy(() => import('../views/ResetPassword'));
const Home = lazy(() => import('../views/Home'));
const GlosarioM1 = lazy(() => import('../components/GlosarioM1'));
const GlosarioM2 = lazy(() => import('../components/GlosarioM2'));
const GlosarioM3 = lazy(() => import('../components/GlosarioM3'));
const Test = lazy(() => import('../views/Test'));

// Modulo 1
const Modulo1 = lazy(() => import('../views/Modulo1'));
const Video = lazy(() => import('../views/Modulo1/Video'));
const MasterClass = lazy(() => import('../views/Modulo1/MasterClass'));
const EvaluacionM1 = lazy(() => import('../views/Modulo1/Evaluacion'));
const EncuestaM1 = lazy(() => import('../views/Modulo1/EncuestaM1'));
const Elearning = lazy(() => import('../views/Modulo1/Elearning'));

// Modulo 2
const Modulo2 = lazy(() => import('../views/Modulo2'));
const VideoM2 = lazy(() => import('../views/Modulo2/Video'));
const MasterClassM2 = lazy(() => import('../views/Modulo2/MasterClass'));
const EvaluacionM2 = lazy(() => import('../views/Modulo2/Evaluacion'));
const EncuestaM2 = lazy(() => import('../views/Modulo2/EncuestaM2'));
const ElearningM2 = lazy(() => import('../views/Modulo2/Elearning'));

// Modulo 3
const Modulo3 = lazy(() => import('../views/Modulo3'));
const VideoM3 = lazy(() => import('../views/Modulo3/Video'));
const MasterClassM3 = lazy(() => import('../views/Modulo3/MasterClass'));
const EvaluacionM3 = lazy(() => import('../views/Modulo3/Evaluacion'));
const EncuestaM3 = lazy(() => import('../views/Modulo3/EncuestaM3'));

// Vistas Back end
const LoginAdministrador = lazy(() => import('../views/Adminstration/Login'));
const HomeAdministrador = lazy(() => import('../views/Adminstration/Home'));
const UsuariosAdministrador = lazy(() => import('../views/Adminstration/Usuarios'));
const UsuariosCrear = lazy(() => import('../views/Adminstration/Usuarios/Crear'));
const UsuariosEliminados = lazy(() => import('../views/Adminstration/Usuarios/Eliminados'));
const UsuariosActualizarAdministrador = lazy(() => import('../views/Adminstration/Usuarios/Actualizar'));
const UsuariosCargarExcel = lazy(() => import('../views/Adminstration/Usuarios/CargarExcel'));

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/glosarioM1' element={<GlosarioM1 />} />
          <Route exact path='/glosarioM2' element={<GlosarioM2 />} />
          <Route exact path='/glosarioM3' element={<GlosarioM3 />} />
          <Route exact path="/recuperar/password/:id/:timestamp" element={<ResetPassword />} />
          <Route exact path='/test' element={<Test />} />

          {/* <Route exact path="/registrate" element={<Registro />} /> */}

          {/* Modulo1 */}
          <Route exact path='/modulo-uno' element={<Modulo1 />} />
          <Route exact path='/modulo-uno/video/:id' element={<Video />} />
          <Route exact path='/modulo-uno/masterclass/:id' element={<MasterClass />} />
          <Route exact path="/modulo-uno/evaluacion" element={<EvaluacionM1 />} />
          <Route exact path="/modulo-uno/encuesta" element={<EncuestaM1 />} />

          {/* Modulo2 */}
          <Route exact path="/modulo-dos" element={<Modulo2 />} />
          <Route exact path="/modulo-dos/video/:id" element={<VideoM2 />} />
          <Route exact path='/modulo-dos/masterclass/:id' element={<MasterClassM2 />} />
          <Route exact path="/modulo-dos/importancia-del-liderazgo-seguro" element={<ElearningM2 />} />
          <Route exact path="/modulo-dos/evaluacion-modulo-dos" element={<EvaluacionM2 />} />
          <Route exact path="/modulo-dos/encuesta" element={<EncuestaM2 />} />

          {/* Modulo3 */}
          <Route exact path="/modulo-tres" element={<Modulo3 />} />
          <Route exact path="/modulo-tres/video/:id" element={<VideoM3 />} />
          <Route exact path='/modulo-tres/masterclass/:id' element={<MasterClassM3 />} />
          <Route exact path="/modulo-tres/evaluacion-modulo-tres" element={<EvaluacionM3 />} />
          <Route exact path="/modulo-tres/encuesta" element={<EncuestaM3 />} />

          {/* Back end⁄ */}
          <Route exact path='/admin/login' element={<LoginAdministrador />} />
          <Route exact path='/admin/dashboard' element={<HomeAdministrador />} />
          <Route exact path='/admin/usuarios' element={<UsuariosAdministrador />} />
          <Route exact path='/admin/usuarios/crear' element={<UsuariosCrear />} />
          <Route exact path='/admin/usuarios/eliminados' element={<UsuariosEliminados />} />
          <Route exact path='/admin/usuarios/cargar-excel' element={<UsuariosCargarExcel />} />
          <Route exact path='/admin/usuarios/:id/actualizar' element={<UsuariosActualizarAdministrador />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
